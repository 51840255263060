import React from 'react'
import styled from 'styled-components'
import { useStaticQuery, graphql } from 'gatsby'
import { motion } from 'framer-motion'

import Container from './Container'

const FooterEl = styled.footer`
  //background: white;
  background-color: #fbfbfb;
  border-top: 1px solid #f6f6f6;
  float: left;
  width: 100%;
  padding: 2rem 0 1rem;

  @media screen and (min-width: 719px) {
    padding: 2rem 0 2rem;
  }
`

const Title = styled.h2`
  margin: 0 0 2rem;
  font-size: 2rem;
`

const FullName = styled.h4`
  margin: 0 0 0.6rem;
  font-size: 1.2rem;
  /* text-transform: uppercase; */
`

const ContactItem = styled.p`
  margin: 0 0 0.3rem;
`

const LinksEl = styled.div`
  margin-top: 2rem;
`

const ContactButton = styled(motion.a)`
  display: inline-block;
  border: 0;
  border-radius: 4px;
  background: #ffbc2d;
  color: white;
  padding: 0.1rem 0.6rem;
  cursor: pointer;
  outline: none;
  margin-right: 1rem;
  font-size: 0.9rem;

  &:hover {
    text-decoration: none;
  }
`

const CompanyInfo = styled.div`
  margin-top: 2rem;
  text-align: center;
  color: rgba(0,0,0,0.5);

  @media screen and (min-width: 719px) {
    margin-top: 6rem;
  }
`

const Footer = () => {
  const data = useStaticQuery(graphql`
    query FooterQuery {
      allSettingsYaml {
        edges {
          node {
            footer_links {
              url
              label
            }
            contact_items {
              text
            }
            full_name
          }
        }
      }
    }
  `)

  const { node: footerData } = data?.allSettingsYaml?.edges[0];

  const buttonMotion = {
    rest: {
      scale: 1,
      boxShadow: '0px 1px 1px rgba(0,0,0,0)',
      backgroundColor: '#ffbc2d',
    },
    hover: {
      backgroundColor: 'transparent',
      color: '#ffbc2d',
      transition: {
        duration: 0,
        ease: 'easeInOut'
      }
    }
  }

  const contactItems = footerData?.contact_items.map(item => (
    <ContactItem key={item.text}>{item.text}</ContactItem>
  ))

  const links = footerData?.footer_links.map(link => (
    <ContactButton 
      key={link.label}
      href={link.url}
      initial='rest'
      whileHover='hover'
      animate='rest'
      variants={buttonMotion}
    >
      {link.label}
    </ContactButton>
  ))

  return (
    <FooterEl>
      <Container>
        <Title>Yhteystiedot</Title>
        {footerData.full_name && (
          <FullName>{footerData.full_name}</FullName>
        )}
        {contactItems && (
          contactItems
        )}
        {links && (
          <LinksEl>
            {links}
          </LinksEl>
        )}

        <CompanyInfo>
          <p>Sami Koodaa Oy<br />3199987-3</p>
        </CompanyInfo>
      </Container>
    </FooterEl>
  )
}

export default Footer