import React from 'react'
import styled from 'styled-components'

const EmailContainer = styled.div`
  display: none;
  position: fixed;
  top: 40px;
  right: 50px;

  @media screen and (min-width: 719px) {
    display: block;
  }
`

const LinkEl = styled.a`
  color: white;
`

const Header = ({ siteTitle }) => (
  <header>
    <EmailContainer>
      <p><LinkEl href="mailto:sami@samikoodaa.fi">sami@samikoodaa.fi</LinkEl></p>
    </EmailContainer>
  </header>
)

export default Header
