import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import styled from 'styled-components'

import Header from '../components/Header'
import Footer from '../components/Footer'
import CurveImage from '../images/curve.svg'
import './index.css'

const WhiteBg = styled.div`
  background: white;
  float: left;
  width: 100%;
  position: relative;
  z-index: 0;
`

const CurveContainer = styled.div`
  max-width: 960px;
  margin: 0 auto;
  position: relative;
`

const Curve = styled.img`
  position: absolute;
  top: -290px;
  right: -558px;
  z-index: -1;
  max-width: none;
  width: 1190px;
  height: 920px;
  transform: rotate(-180deg);

  @media screen and (min-width: 719px) {
    top: -22px;
  }
`

const Main = styled.main`
  margin-top: 1rem;

  @media screen and (min-width: 719px) {
    margin-top: 8rem;
  }
`

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <>
      <WhiteBg>
        <CurveContainer>
          <Curve src={CurveImage} />
        </CurveContainer>
        <Header siteTitle={data.site.siteMetadata?.title || `Title`} />
        <Main>{children}</Main>
      </WhiteBg>
      <Footer>
        <h3>Sami Juvonen</h3>
      </Footer>
    </>
  )
}

export default Layout
